import {
  Banner,
  Box,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  Layout,
  Link,
  Row,
  Theme,
  media,
  routesObject
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import ListeningToRelatedPages from "../../../components/our-approach/ListeningToRelatedPages"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import styled from "styled-components"

const theme = Theme.youthFull
interface Props {
  location: { pathname: string }
  data: {
    site: Site
    ogImage: IGatsbyImageData
  }
}

const FriendlyTile = styled.div`
  ${media.large`
    :not(:last-child) {
      margin-right: 2rem;
    }
  `}
  margin-bottom: 2rem;
  a {
    color: ${theme.dark};
  }
`

const GreyTile = ({
  title,
  children
}: {
  title: string
  children: React.ReactChild
}) => (
  <FriendlyTile className="bg-lwb-grey-xxx-light w-50-l pa4-ns pa3">
    <Heading size={4} topMargin="none" className="color-lwb-theme-dark">
      {title}
    </Heading>
    <div>{children}</div>
  </FriendlyTile>
)

const ThemeTile = ({
  title,
  children
}: {
  title: string
  children: React.ReactChild
}) => (
  <FriendlyTile className="bg-lwb-theme-xxx-light w-50-l pa4-ns pa3">
    <h4 className="copy-lrg fw8 color-lwb-theme-dark">{title}</h4>
    <div>{children}</div>
  </FriendlyTile>
)

const StyledList = styled.ul`
  padding-left: 28px;
  margin-top: 0.5rem;
  li {
    margin-top: 0.5rem;
  }
`

const ListeningToCarersPage = ({
  location,
  data: {
    site: { siteMetadata },
    ogImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Listening to carers | ${siteMetadata.title}`}
        description="We listen to Carers and local care teams to understand of the lived experience of carers, their family and others in the care environment."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={ogImage}
      />
      <Layout theme={theme}>
        <Banner
          title="Listening to carers"
          subTitle="We listen to carers and local care teams to understand of the
          lived experience of carers, their family and others in the care
          environment."
          breadcrumbs={[
            routesObject.home,
            {
              to: "/",
              text: "Our approach",
              title: "Return to parent page"
            },
            {
              to: "..",
              text: "Child, youth and family",
              title: "Return to main page"
            }
          ]}
        />

        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>Understanding the carer journey</Heading>
              <p>
                Amplifying the voice of supported, knowledgeable carers and
                employees, who are focused on improving the lives of children in
                care, strengthens the Life Without Barriers focus on developing
                a world class, evidence-based foster and kinship care program.
              </p>
              <Heading size={3}>How do we listen to carers?</Heading>
              <p>
                Conducting online surveys and focus groups with carers and local
                care teams has enabled us to understand the carer journey - what
                we are doing well, where the gaps are and where there are
                opportunities for improvement.
              </p>
              <p>
                Our local teams maintain ongoing contact with our carers and
                through engagement events such as training and support meetings
                we provide an open and safe environment to share thoughts, ideas
                and suggestions on care.
              </p>
              <Heading size={3}>What are we learning?</Heading>
              <p>
                The foster care system is challenged by a limited number of
                people considering becoming, low carer respite options, high
                expectations of accountability and varying levels of practical
                support for children, young people. Despite these challenges,
                Life Without Barrier&apos;s carers remain deeply committed to
                supporting children and young people to succeed in life.
              </p>
              <p>Carers have shared where we can support them more:</p>
              <div className="pt4">
                <div className="flex-l">
                  <GreyTile title="Carer attraction and engagement">
                    <p className="mt2">
                      We need to attract carers motivated by altruistic factors
                      (such having the time and energy to help a child,
                      prioritising the needs of children and wanting to play a
                      part in the community).
                    </p>
                  </GreyTile>
                  <GreyTile title="Carer assessment, approval and review">
                    <p className="mt2">
                      The outcomes for children and young people improve where
                      they are well matched with families who are skilled,
                      confident and resilient.
                    </p>
                  </GreyTile>
                </div>
                <div className="flex-l">
                  <GreyTile title="Carer learning pathways">
                    <p className="mt2">
                      There are mixed perspectives on how well carers are
                      trained in preparation for their role in foster care.
                      Access to learning continues to be a challenge. Short
                      notice, time constraints, scheduling and access to
                      childcare can limit availability for training.
                    </p>
                  </GreyTile>
                  <GreyTile title="Carer support and supervision">
                    <p className="mt2">
                      There is strong support for a more cohesive sense of ‘care
                      team’ that involves carers. Views about the involvement of
                      children’s family identified many factors that can help
                      develop a more inclusive care model.
                    </p>
                  </GreyTile>
                </div>
              </div>
              <h3 className="fw8 ts-display-4 mt4">
                What are we doing with this information?
              </h3>
              <p>
                By listening to and engaging with carers, we acknowledge the
                need for ongoing development and support. Building positive
                relationships – trust, transparency, accountability, goodwill –
                are key elements for the development of a great care system.
              </p>
              <p>
                Across key focus areas of the carer journey we are progressing
                work to:{" "}
              </p>
              <div className="pt4">
                <div className="flex-l">
                  <ThemeTile title="Carer attraction and engagement">
                    <StyledList>
                      <li>
                        Introduce specialist functions and selected carers as
                        ambassadors to attract and engage with potential carers
                        in local communities.
                      </li>
                      <li>
                        Adopt innovative IT solutions for enquiry management.
                      </li>
                      <li>
                        Enhance the way we provide information to prospective
                        carers through our website and other approaches.
                      </li>
                    </StyledList>
                  </ThemeTile>
                  <ThemeTile title="Carer assessment, approval and review">
                    <StyledList>
                      <li>
                        Extend our assessment approach to learn more about who
                        carers are, in addition to what they know and can do.
                        This focus on attributes reflects research and supports
                        positive outcomes for children.
                      </li>
                      <li>
                        Explore culturally appropriate assessment approaches,
                        including a Winangay pilot.
                      </li>
                    </StyledList>
                  </ThemeTile>
                </div>
                <div className="flex-l">
                  <ThemeTile title="Carer learning pathways">
                    <StyledList>
                      <li>
                        Extend CARE and trauma-informed training to carers and
                        the broader care team.
                      </li>
                      <li>
                        Develop eLearning modules and other approaches that help
                        build the knowledge base and practical skills of our
                        care teams.
                      </li>
                      <li>
                        Introduce anytime, anywhere, any pace training for all
                        in the care team through the Life Without Barriers
                        myLearning platform.
                      </li>
                    </StyledList>
                  </ThemeTile>
                  <ThemeTile title="Carer support and supervision">
                    <StyledList>
                      <li>
                        Develop online support information – such as the{" "}
                        <Link rooted to="/foster-care/support/carer-guide/">
                          Carer Guide
                        </Link>
                        .
                      </li>
                      <li>
                        Implement peer support models such as{" "}
                        <Link to="/our-approach/child-youth-and-family/mockingbird-family/">
                          MOCKINGBIRD FAMILY&trade;
                        </Link>
                        .
                      </li>
                      <li>
                        Strengthen the skills of our team leaders and others to
                        supervise and support high quality work.
                      </li>
                      <li>
                        Creating a digital communication and information
                        platform for carers to access current information and
                        connect with one another.
                      </li>
                    </StyledList>
                  </ThemeTile>
                </div>
              </div>
            </Box>
          </Row>
        </Container>

        <Container>
          <Row>
            <Box>
              <Heading size={2}>Contact us</Heading>
              <div className="flex-l items-center-l flex-wrap-l">
                <div className="dib-ns mb2 mr3">
                  <p className="pt2">
                    For more information contact us via phone or email:
                  </p>
                </div>
                <div className="flex-l mt1-l">
                  <div className="dib mr4 mt2">
                    <ContactUsPhoneLink
                      marginClasses="mt0"
                      phoneNumber="1800573184"
                    />
                  </div>
                  <div className="dib mt2">
                    <ContactUsEmailLink
                      marginClasses="mt0"
                      text="lwbcarers@lwb.org.au"
                      link="mailto:lwbcarers@lwb.org.au"
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Row>
        </Container>

        <ListeningToRelatedPages currentPathname={location.pathname} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { regex: "/img-our-approach-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default ListeningToCarersPage
